import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiBaseService } from '@mp/shared/data-access';

import { CreateOrganisation, Organisation, UpdateOrganisation } from './organisation';

@Injectable({ providedIn: 'root' })
export class OrganisationenService extends ApiBaseService<Organisation, CreateOrganisation, UpdateOrganisation> {
  constructor(http: HttpClient) {
    super(http, '/api/core/organisationen');
  }

  addUserToOrganisationen(organisationIds: Array<number>, userId: number): Observable<Array<boolean>> {
    return forkJoin(
      organisationIds.map((organisationId: number) =>
        this.http
          .put<boolean>(`/api/core/organisationen/${organisationId}/benutzer/${userId}`, null, { observe: 'response' })
          .pipe(map((response: HttpResponse<boolean>) => response.status === 204)),
      ),
    );
  }

  removeUserFromOrganisation(organisationIds: Array<number>, userId: number): Observable<Array<boolean>> {
    return forkJoin(
      organisationIds.map((organisationId: number) =>
        this.http
          .delete<boolean>(`/api/core/organisationen/${organisationId}/benutzer/${userId}`, { observe: 'response' })
          .pipe(map((response: HttpResponse<boolean>) => response.status === 204)),
      ),
    );
  }
}
